

import UMUM from  '../../library/umum.js'


export default {
  data() {
    return {

      form : {
        _id : '',
        uraian : '',
        menu : [],
        createdAt : '',
      },
     orange : true,

      list_data : [],
      list_menu : [],

      page_limit : 8,
      page_first: 1,
      page_last: 0,
      cari_value: "",
      cek_load_data : true,

      mdl_view: false,
      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      btn_add: false,
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_LEVEL_USERS + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              page_limit : this.page_limit,
              cari_value: this.cari_value
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data;
              // this.page_last = res_data.jmlData;
              this.$store.commit("hideLoading");
              // console.log(res_data);
      });
    },


    addData : function(number) {
          var data =  UMUM.filterAddEditRemove(this.list_menu)

          this.form.createdAt = UMUM.NOW()
          fetch(this.$store.state.url.URL_LEVEL_USERS + "addData", {
              method: "POST",
              headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
              },
              body: JSON.stringify({
                
                uraian : this.form.uraian,
                createdAt : this.form.createdAt,
                menu : data
              })
          }).then(res_data => {
              this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
              this.getView();
          });

        // console.log(this.list_menu)

            console.log(data)


    },


    editData : function(){
      var data =  UMUM.filterAddEditRemove(this.list_menu)
      fetch(this.$store.state.url.URL_LEVEL_USERS + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
                _id : this.form._id,
                uraian : this.form.uraian,
                createdAt : this.form.createdAt,
                menu : data
              })
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_LEVEL_USERS + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({_id : this.form._id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },


    selectData : function(data){
      console.log(data)
        this.form = {
          _id : data._id,
          uraian : data.uraian,
          menu : data.menu,
          createdAt : data.createdAt,
        };

        this.getMenu();
    },
    

    getMenu : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_MENU_LIST + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              page_limit : this.page_limit,
              cari_value: this.cari_value
          })
      })
          .then(res => res.json())
          .then(res_data => {
              UMUM.setFalseAddEditRemove(res_data, this.form.menu)
              this.list_menu = res_data;
              
            //   this.page_last = res_data.jmlData;
              this.$store.commit("hideLoading");
              console.log(this.list_menu);
      });
    },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },





    // ====================================== PAGINATE ====================================







  },

  mounted () {
    this.getView();
    // this.getMenu();





    const res_data1 = [
      { name: 'Swimming', ages: [6, 7, 8], maxCap: 10 },
      { name: 'Skating', ages: [8, 9, 10], maxCap: 20 },
      { name: 'Open Gym', ages: [10, 11, 12], maxCap: 30 }
    ];
    
    const ageAppropriate1 = res_data1.filter(element => (element.name == 'Skating'));

    console.log(ageAppropriate1.length)







  },
}
